import { Link } from 'gatsby';
import React from 'react';
import Layout from '../components/layout';
import * as styles from '../styles/styleconstants'


export default function About() {
    return (
      <Layout>
      <main style = {styles.pageStyles}>
          <title> About </title>
          <h1 style= {styles.headingStyles}>
              About
          </h1>
          <p style = {styles.descriptionStyle}>
          Pianist and composer <b>Liam Kaplan</b> has appeared at Weill Recital Hall, Merkin Hall, Tenri Cultural Institute, 
          and many other venues across the United States and Iceland. Herdís Guðmundsdóttir premiered his Violin Concerto with
          Tim Weiss and the Oberlin Contemporary Ensemble in December 2020. In 2019, soprano Diana Schwam premiered his song 
          cycle <span style = {styles.italicDesc}>New Hampshire Songs</span>, based on poems by Robert Frost, with the composer at the piano. Raphael Jiménez led the
          Oberlin Chamber Orchestra in the premiere of his <span style={styles.italicDesc}>Rhapsody on a Chorale</span> for string orchestra in 2018. Liam has made
          solo appearances in Bartok's 3rd Piano Concerto with the Aspen Conducting Academy Orchestra, Liszt's 2nd Piano 
          Concerto with the Oberlin Orchestra, and in Messiaen's <span style = {styles.italicDesc}>Couleurs de la cité céleste</span> with the Oberlin Sinfonietta. 
          His debut recording of Bach's <span style = {styles.italicDesc}>Well-Tempered Clavier, Book II</span> was released in July 2019, supported by a FIG Grant 
          from Oberlin Conservatory. In 2020, Liam released his second solo album which features Bach's <span style = {styles.italicDesc}>Goldberg Variations </span> 
          alongside premiere recordings of Elizabeth Ogonek's <span style = {styles.italicDesc}>Orpheus Suite</span> and his own 8 Preludes, which earned a BMI Student
          Composer Award. His playing can also be heard on two records with the Oberlin Contemporary Ensemble: <span style = {styles.italicDesc}>The Water Cantos </span>
          by Elizabeth Ogonek and <span style = {styles.italicDesc}>Snippet Variations</span> by Jesse Jones.
          </p>
          <p style = {styles.descriptionStyle}>
          Liam holds a Bachelor of Music degree in Piano Performance and Composition from Oberlin Conservatory, where he studied
          with Alvin Chow and Stephen Hartke. He is the fourth student to successfully complete Oberlin's Honors Program in Piano
          Performance since its inception in 2001. Liam won Oberlin's Senior Concerto, John Elvin, and Rudolf Serkin competitions,
          as well as the Faustina Hurlbutt Prize, the Walter E. Aschaffenburg Prize for composition, the Pi Kappa Lambda Prize for
          Musicianship, the Ernst Hatch Wilkins Memorial Prize and the OFMC Agnes Fowler Scholarship. He has participated in summer
          music festivals including the Aspen Music Festival and School where he won the 2019 ACA Piano Concerto Competition, the 
          Meadowmount School of Music, and Art of the Piano at CCM. Liam studied piano with Marcia Eckert, composition with Eleanor
          Cory, double bass with Judith Sugarman, and music theory with Gordon Minette at Mannes Prep. Piano teachers also include 
          his mentor Seymour Bernstein, who is the subject of Ethan Hawke's acclaimed documentary, <span style = {styles.italicDesc}>Seymour: An Introduction</span>, in which
          he is a featured performer.
          </p>
          <p style = {styles.descriptionStyle}>
          In addition to his activities as a pianist and composer, Liam maintains a freelance career as a music arranger, editor, engraver, and educator. 
          <Link to = "/contact" style = {styles.linkStyle}> Contact him</Link> for samples of his engraving work. 
          He lives in New Haven, Connecticut.
          </p>
          <p style = {styles.bottomSpacer}> </p>
      </main>
      </Layout>
    )
  }